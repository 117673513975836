.cookie-button {
    margin-right: 2vh;
}

.cookiePolicyContent {
    h1 {
        a {
            text-decoration: underline;
        }
    }
}
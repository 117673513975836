.principal-container {
  overflow-x: hidden;
}

.principal-div {
  position: relative;
}

.container-md,
.container-sm,
.container {
  padding: 0;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 20px;
  border-radius: 10%;
  padding: 10px;
  z-index: 9999;
  font-size: 20px;
}

@media (orientation: portrait) {
  div.policy {
    padding-top: 14vh;
  }
}

.policy {
  padding-top: 12vh;
  position: absolute;
  left: 5vh;

  .policy-text {
    width: 95%;

    .faqs-section {
      padding-left: 0;


      li {
        margin-bottom: 3vh;
      }

      li:first-of-type {
        margin-top: 3vh;
      }

      li:last-of-type {
        margin-bottom: 0;
      }
    }

    .question-red {
      font-size: 24px;
      color: hsl(var(--clr-zgz-barbell-red));
    }
  }

  div:first-of-type {
    margin-bottom: 1vh;
  }

  li:first-child {
    margin-left: 0.7em;
  }
}

@media (min-width: 576px) and (max-width: 1439px) {

  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {

  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 100%;
  }
}
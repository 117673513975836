.section-description {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 0 auto;
}

.section-description.brand-description {
  display: block;

  div {
    width: 80%;
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.gym-detail-popup {
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  position: fixed;
  left: 0;
  z-index: 100;
  top: 12.5vh;
  height: 88%;
}

.detail-button {
  align-self: flex-start;
}

.detail-text {
  flex-grow: 1;
}

.detail-title {
  margin: 2vh;
}

.close-button {
  position: absolute;
  top: 2vh;
  right: 2vh;
  z-index: 99;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  button {
    margin: -1vh 0 0 1vh;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 12%;
}

.club-title-description {
  color: hsl(var(--clr-zgz-barbell-white));
}

.gym-title,
.club-title {
  margin-bottom: 3vh;
}

.zgzbc1 {
  background-image: url('../assets/img/index/zgzbc1.jpg');
}

.zgzb1 {
  background-image: url('../assets/img/index/zgzb1.jpg');
}

.gym-entry {
  background-image: url('../assets/img/gym/gym-entry.jpg');
}

.powerzone {
  background-image: url('../assets/img/gym/powerzone.jpg');
}

.buildingzone {
  background-image: url('../assets/img/gym/buildingzone.jpg');
}

.powergear-combo-rack {
  background-image: url('../assets/img/gym/machines/powergear-combo-rack.jpg');
}

.rack-barbell-wizard {
  background-image: url('../assets/img/gym/machines/rack-barbell-wizard.jpg');
}

.rogue-bench {
  background-image: url('../assets/img/gym/machines/rogue-bench.jpg');
}

.power-bench {
  background-image: url('../assets/img/gym/machines/power-bench.jpg');
}

.lifting-platform-powergear {
  background-image: url('../assets/img/gym/machines/lifting-platform-powergear.jpg');
}

.lifting-platform-strenghtshop {
  background-image: url('../assets/img/gym/machines/lifting-platform-strenghtshop.jpg');
}

.power-bar-plates-calibrated {
  background-image: url('../assets/img/gym/bars-plates/power-bar-plates-calibrated.jpg');
}

.calibrated-little-plates {
  background-image: url('../assets/img/gym/bars-plates/calibrated-little-plates.jpg');
}

.plates-bw {
  background-image: url('../assets/img/gym/bars-plates/plates-bw.jpg');
}

.bastard-power-bar {
  background-image: url('../assets/img/gym/bars-plates/bastard-power-bar.jpg');
}

.bow-bar {
  background-image: url('../assets/img/gym/bars-plates/bow-bar.jpg');
}

.cambered-bar {
  background-image: url('../assets/img/gym/bars-plates/cambered-bar.jpg');
}

.spider-bar {
  background-image: url('../assets/img/gym/bars-plates/spider-bar.jpg');
}

.cambered-bench-row-bar {
  background-image: url('../assets/img/gym/bars-plates/cambered-bench-row-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.original-bar {
  background-image: url('../assets/img/gym/bars-plates/original-bar.jpg');
}

.t-row {
  background-image: url('../assets/img/gym/machines/t-row.jpg');
}

.shoulder-machine {
  background-image: url('../assets/img/gym/machines/shoulder-machine.jpg');
}

.bench-press {
  background-image: url('../assets/img/gym/machines/bench-press.jpg');
}

.seal-row {
  background-image: url('../assets/img/gym/machines/seal-row.jpg');
}

.pulley-multistation {
  background-image: url('../assets/img/gym/machines/pulley-multistation.jpg');
}

.utlity-bench {
  background-image: url('../assets/img/gym/machines/utlity-bench.jpg');
}

.dumbell {
  background-image: url('../assets/img/gym/bars-plates/dumbell.jpg');
}

.hittrust {
  background-image: url('../assets/img/gym/machines/hittrust.jpg');
}

.pendulum-leg-press {
  background-image: url('../assets/img/gym/machines/pendulum-leg-press.jpg');
}

.seated-femoral {
  background-image: url('../assets/img/gym/machines/seated-femoral.jpg');
}

.westside-hyper {
  background-image: url('../assets/img/gym/machines/westside-hyper.jpg');
}

.leg-press {
  background-image: url('../assets/img/gym/machines/leg-press.jpg');
}

.cuads-femoral {
  background-image: url('../assets/img/gym/machines/cuads-femoral.jpg');
}

@media (orientation: portrait) {
  .section-description {
    position: relative;
    width: 100%;
  }

  .gym-description img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .description-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    width: 100%;
    padding: 10px;
  }

  .description-overlay.gym-section {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    width: 100%;
    padding: 10px;
    top: 1vh;
  }

  .gym-detail-popup {
    top: 12vh;
    height: 88%;
  }

  .div-img {
    min-width: 100%;
  }
}

@media (orientation: landscape) {
  .carousel-indicators {
    display: none;
  }

  .section-description {
    padding: 0 10vh;
    width: 90%;
    margin: 0 auto;

    .img,
    .description-overlay {
      padding: 0 1vh;
      flex: 50%;
    }
  }

  .section-description.img-right {
    display: flex;

    .img {
      order: 2;
    }

    .description-overlay {
      order: 1;
    }
  }

  .gym-detail-popup {
    top: 10vh;
    height: 90%;
  }
}
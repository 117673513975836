:root {
    /* colors */
    --clr-body: 0 0% 8%;
    --clr-off-white: 0 0% 86%;
    --clr-zgz-barbell-white: 0 0% 100%;
    --clr-zgz-barbell-red: 2 79% 42%;
    --clr-zgz-barbell-red-hover: 2 79% 52%;
    --clr-zgz-barbell-red-active: 2 79% 62%;
    --clr-zgz-barbell-red-disabled: 2 79% 30%;
    --clr-zgz-barbell-black: 0 0% 0%;
    --clr-outer-dark: 227 11% 15%;
    --clr-outer-dark: 227 11% 15%;
    --clr-info-dark: 231 12% 12%;
    --clr-footer-dark: 0 0% 20%;
    --clr-footer-icons: 0 0% 50%;
    --clr-stack: 25 79% 78%;

    /* font sizes */
    --fs-700: 1.75rem;
}

.ReactModal__Overlay {
    z-index: 9999;

    .ReactModal__Content {
        background-color: hsl(var(--clr-zgz-barbell-black));
        color: hsl(var(--clr-zgz-barbell-red));
        opacity: 0.9;
    }
}

#carousel::before,
#team::before,
#gym::before,
#club::before,
#rates::before,
#contact::before {
    content: "";
    display: block;
    height: 14vh;
    margin-top: -14vh;
    visibility: hidden;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 5px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #C01D16;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: hsl(var(--clr-zgz-barbell-red));
    border-radius: 15%;
    width: 1.5rem;
    height: 1.5rem;
}

.home-carousel {
    img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 2.5rem;
        height: 2.5rem;
    }

    .carousel-control-prev,
    .carousel-control-next {
        color: hsl(var(--clr-zgz-barbell-red));
        width: 30%;
    }

    .fullscreen-video {
        min-width: 100%;
        min-height: 100%;
        width: 100vh;
        height: 100vh;
    }

    .carousel-item {
        .carousel-caption {
            background-color: hsl(var(--clr-zgz-barbell-black));
            opacity: 0.8;
            border-radius: 3px;
        }
    }
}

.button-zgz-barbell {
    border-radius: 6px;
    color: hsl(var(--clr-zgz-barbell-white));
    background-color: hsl(var(--clr-zgz-barbell-red));
    border: none;
    cursor: pointer;
}

.button-zgz-barbell:hover {
    color: hsl(var(--clr-zgz-barbell-white));
    background-color: hsl(var(--clr-zgz-barbell-red-hover));
}

.section-separation {
    margin: 3rem auto;
}

.div-img {
    background-size: cover;
    background-position: center;
    min-height: 50vh;
}

.div-img-item.with-img {
    position: relative;
    background-size: cover;
    background-position: center;
}

.div-img-item.with-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    /* Asume que el color de fondo es blanco */
    z-index: 1;
}

.div-img-item.with-img>* {
    position: relative;
    z-index: 2;
}

.div-img.card-img {
    min-height: 35vh;
}

.rosi {
    background-image: url('../assets/img/team/Rosi.jpg');
}

.david {
    background-image: url('../assets/img/team/David.jpg');
}

.christian {
    background-image: url('../assets/img/team/Christian.jpg');
}

.pablo {
    background-image: url('../assets/img/team/Pablo.jpg');
}

.ruben {
    background-image: url('../assets/img/team/Ruben.jpg');
}

/* @media (orientation: portrait) {

    .zgzb1,
    .gym-entry,
    .powerzone,
    .buildingzone,
    .zgzbc1 {
        min-width: 100%;
    }
} */
.section-separation.list-info {
    width: 70%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .listInfo-section {
        border-bottom: 1px solid hsl(var(--clr-zgz-barbell-red));

        p {
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }

    .listInfo-section.with-img {
        padding: 1rem
    }

    .listinfo-footer {
        margin-top: 1.5rem;
        padding-top: 10px;

        p {
            margin-bottom: 0;
        }
    }

    .listInfo-section:first-of-type {
        margin-top: 1.5rem;
    }
}
.contact-section {
    .contact-header {
        margin-bottom: 2vh;

        p {
            margin: 0 1vh;
        }

        .social-network-contact {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3vh;
            font-size: 3vh;

            a {
                margin: 0 1vh;
            }

        }
    }

    .form-section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 3vh;

        .form {
            width: 302px;

            .form-group {
                margin-top: 1vh;

                textarea.form-control {
                    min-height: calc(10rem + calc(var(--bs-border-width) * 2));
                }
            }
        }
    }
}

.spinner-border {
    margin: 1vh;
    position: absolute;
    color: hsl(var(--clr-zgz-barbell-red));
}
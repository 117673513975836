a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  cursor: pointer;
}

html {
  font-size: 14px;
}

header {
  position: fixed;
  top: 0;
  z-index: 9;
  background-color: hsl(var(--clr-zgz-barbell-white));
  width: 100%;
  opacity: 0.8;

  .navbar {
    font-weight: bold;


    .navbar-collapse {
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center;
      padding-right: 5vh;
    }
  }
}

.nav-item {
  margin-left: 1.5em;
}

.navbar-toggler {
  margin-right: 2rem;
  font-size: 35px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.nav-link {
  cursor: pointer;
}
.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;


    i {
        margin-right: 0.3em;
    }
}

.footer-container {
    width: 80%;
    margin: 0 auto;
    display: inline-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .email {
        a+a {
            margin-left: 1.5vh;
        }

        svg {
            margin-right: 0.5vh;
        }
    }

    .email,
    .address {
        a {
            color: hsl(var(--clr-zgz-barbell-black));
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .email+.address {
        margin-bottom: 1.3vh;
    }
}

.links {
    margin-bottom: 20px;

    div {
        display: inline-block;
    }
}

.links a {
    margin: 0 15px;
}

.copyright {
    color: #6c757d;
}

.logo-footer {
    justify-content: space-between;

    .logo {
        width: 35vh;
    }

    .social {
        font-size: 2.4vh;

        a {
            padding: 2vh;
        }
    }
}

.section-separation.footer {
    margin: 3rem auto auto auto;
}